.authorization {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(circle, #ffffff, #f5f5f5, #ebebeb, #e2e1e1, #d8d7d7);
}

.authorization-panel {
    max-width: 450px;
    width: 100%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 20px #d8d7d7;
    border-radius: 10px;
    padding-bottom: 40px;
    margin: 0 20px 50px 20px;
}

/* Logo */
.authorization-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.authorization-logo img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.authorization-logo-text {
    padding-left: 10px;
}

.authorization-logo-name {
    font-family: 'Red Hat Display';
    font-weight: 1000;
    font-size: 18px;
    color: #141414;
    width: 200px;
}

.authorization-logo-subtext {
    font-family: 'Red Hat Display';
    font-weight: 300;
    font-size: 16px;
    color: #787878;
    margin-top: 2px;
}

.authorization-button {
    background: var(--primary);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 25px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.authorization-button:hover {
    box-shadow: 0 0 20px var(--primary);
}