.page-404 {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.page-404-title {
    font-size: 80px;
    font-weight: 900;
    font-family: 'Red Hat Display';
    color: #141414
}

.page-404-text {
    color: #555;
    font-size: 14px;
}