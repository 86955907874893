.page {
    min-height: 100vh;
    padding-right: 20px;
    margin-bottom: 100px;
}

@media screen and (max-width: 900px) {
    .page {
        padding: 0 20px;
    }
}

/* Page Header */
.page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    margin: 20px 0;
}

.page-header-text {
    font-size: 25px;
    font-weight: 900;
    color: #141414;
    padding-right: 15px;
}

.page-header-add {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    width: 110px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.page-header-add:hover {
    box-shadow: 0 0 10px var(--primary);
}

.page-header-add img {
    width: 14px;
    height: 14px;
    object-fit: contain;
}

.page-header-add-text {
    color: white;
    font-weight: bold;
    padding-left: 6px;
    font-size: 14px;
}


@media screen and (max-width: 900px) {

    .page-header {
        margin: 10px 0;
    }

    .page-header-text {
        font-size: 18px;
    }

}


/* Page Header Filter Styles */
.page-search {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-search form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-search form input {
    height: 40px;
    width: 240px;
    font-size: 13px;
    padding-left: 10px;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 50px;
    border: 1px solid #eaeaea;
    outline: none;
}

.page-search form button {
    padding-top: 2px;
    background: var(--primary);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: -40px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.page-search form button:hover {
    box-shadow: 0 0 20px var(--primary);
}

.page-search form button img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.page-reload {
    padding-top: 2px;
    background: #232323;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.3s;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
}

.page-reload:hover {
    box-shadow: 0 0 10px #232323;
}

.page-reload img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}


.page-filter {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.page-filter span {
    font-size: 13px;
    padding-right: 3px;
    color: #555
}

.page-filter input {
    width: 103px;
    height: 40px;
    padding-left: 15px;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    margin-right: 5px;
}

.page-filter>button {
    background: #03a9f4;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    font-weight: bold;
    padding-top: 5px;
    transition: box-shadow 0.3s;
}

.page-filter>button:hover {
    box-shadow: 0 0 10px #03a9f4;
}

.page-filter>button img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.react-datepicker__day--in-range {
    background: #03a9f4 !important;
}

.page-csv {
    background: #ff9800;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    font-weight: bold;
    padding-top: 5px;
    transition: box-shadow 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding-bottom: 4px;
    margin-left: 10px;
}

.page-csv:hover {
    box-shadow: 0 0 20px #ff9800;
}


@media screen and (max-width: 900px) {

    .page-filter-header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 150px;
    }

    .page-filter-header .page-header-text {
        font-size: 22px;
        margin-top: 10px;
    }

    .page-search {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .page-reload {
        margin-left: 5px;
    }

    .page-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
    }

    .page-csv {
        margin-left: 5px;
    }

    .page-filter input {
        width: 80px;
        padding-left: 5px;
        font-size: 12px;
    }

}



/* Page Content */
.page-content {
    background: white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px #d8d7d7;
    min-height: calc(100vh - 150px);
}

.page-content-row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin: 0 10px;
    border-bottom: 1px solid #dadada;
}

.page-content-row.head {
    cursor: default !important;
}

.page-content-row.head .page-content-item,
.page-content-row.head .page-content-number,
.page-content-row.head .page-content-more {
    font-size: 14px;
    font-weight: 300;
    color: #555;
    border-bottom: none;
}

.page-content-item,
.page-content-number,
.page-content-more {
    font-size: 14px;
    color: #141414;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.page-brand .page-content-item {
    width: calc(90%/2);
}

.page-request .page-content-item {
    width: calc(100%/4);
}

.page-request .page-content-item:nth-child(4) {
    width: calc(100%/2);
}

.page-admin-table .page-content-item {
    width: calc(90%/2);
}

.page-content-item {
    width: 30%;
}

.page-content-item.bold {
    font-weight: bold;
}

.page-content-number {
    width: 5%;
}

.page-content-more {
    width: 5%;
    position: relative;
    text-align: right;
}

.page-content-more-icon {
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.page-content-more-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.page-content-more-icon img {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.page-content-more-menu {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50px;
    background: white;
    padding: 10px 15px;
    border: 1px solid #FAFAFA;
    box-shadow: 0 0 10px #BBB;
    min-width: 250px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
    border-radius: 15px;
}


.page-content-more-menu::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 19px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    transform: rotate(45deg);
}

.page-content-more:hover .page-content-more-menu {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}


.page-content select {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: white;
    border: 1px solid #dedede;
    padding: 0 25px;
    font-size: 14px;
    outline: none;
    caret-color: var(--primary);
}


.page-content-more-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    padding: 5px 0;
    cursor: pointer;
    width: 100%;
}

.page-content-more-menu-item img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.page-content-more-divider {
    border-bottom: 1px solid #f2f2f2;
}

.page-content-more-menu-item span {
    margin-left: 5px;
    color: #232323;
    font-size: 14px;
}

.page-content .tag {
    background: var(--primary);
    width: 100px;
    height: 25px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 900;
}

.page-content .tag.blue {
    background: #03a9f4;
}

.page-content .tag.purple {
    background: #3f51b5;
}

.page-content .tag.red {
    background: #f44336;
}


/* TYPES STYLES */
.page-form-types {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #FAFAFA;
    border-radius: 25px;
    margin: 7px 0;
}

.page-form-type {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #222;
    cursor: pointer;
    height: 50px;
    border-radius: 25px;
}

.page-form-type.active {
    background: #222;
    color: #fff;
}


.content-input-file {
    position: relative;
    display: block;
    outline: none;
    margin: 30px 0;
}

.content-input-file-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 13px;
    vertical-align: middle;
    text-align: center;
    border-radius: 6px;
    height: 100px;
    padding: 10px 20px;
    line-height: 22px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: transform 0.5s;
    font-weight: 800;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #999;
}

.content-input-file-text {
    padding: 0 10px;
    line-height: 40px;
    display: inline-block;
    font-size: 12px;
    color: #666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-input-file-text img {
    width: 100%;
    max-height: 300px;
    height: 100%;
    object-fit: cover;
    margin-top: 20px;
    border: 1px solid #cacaca;
    border-radius: 10px;
}

.content-input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

/* Focus */
.content-input-file input[type=file]:focus+.content-input-file-button {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Disabled */
.content-input-file input[type=file]:disabled+.content-input-file-button {
    background-color: #eee;
}



@media screen and (max-width: 900px) {

    .page-content-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid #dadada;
        padding: 10px 0;
    }

    .page-content-row.head {
        display: none;
    }

    .page-content-more {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 5px;
        width: 40px;
        height: 40px;
        padding: 0;
    }

    .page-content-number {
        display: none;
    }

    .page-game-table .page-content-item,
    .page-round-table .page-content-item,
    .page-video-table .page-content-item,
    .page-admin-table .page-content-item,
    .page-content-item {
        width: 100%;
        padding: 0 5px;
        min-height: 30px;
    }

    .page-round-table .page-content-item:nth-child(2)::before {
        content: "Номер игры: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-round-table .page-content-item:nth-child(3)::before {
        content: "Номер cтола: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-round-table .page-content-item:nth-child(4)::before {
        content: "Игра: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-round-table .page-content-item:nth-child(5)::before {
        content: "Количество боксов: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-round-table .page-content-item:nth-child(6)::before {
        content: "Ставка: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-round-table .page-content-item:nth-child(6)::before {
        content: "Выплата: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }



    .page-game-table .page-content-item:nth-child(3)::before {
        content: "Min: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-game-table .page-content-item:nth-child(4)::before {
        content: "Max: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-game-table .page-content-item:nth-child(5)::before {
        content: "Max Pay: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-content-table .page-content-item:nth-child(2)::before {
        content: "Номер стола: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-content-table .page-content-item:nth-child(3)::before {
        content: "Пин код дилера: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-content-table .page-content-item:nth-child(4)::before {
        content: "Пин код стола: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-video-table .page-content-item:nth-child(2)::before {
        content: "Название: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-video-table .page-content-item:nth-child(3)::before {
        content: "Ccылка: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-admin-table .page-content-item:nth-child(2)::before {
        content: "Имя: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

    .page-admin-table .page-content-item:nth-child(3)::before {
        content: "Email: ";
        font-size: 14px;
        color: #333;
        font-weight: 300;
    }

}


/* Form Styles */
.page-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.page-form-box {
    width: 48%;
}

.page-form-bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.page-form-bottom.centered {
    justify-content: center;
}

.page-form-button {
    width: 48%;
    background: var(--primary);
    height: 50px;
    margin: 20px 0;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 900;
    font-size: 14px;
    transition: box-shadow 0.4s;
    cursor: pointer;
}

.page-form-button:hover {
    box-shadow: 0 0 20px var(--primary);
}

.page-form-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #dadada;
    padding: 10px;
    border-radius: 25px;
}

.page-form-switch span {
    font-size: 14px;
    padding-right: 20px;
    padding-left: 10px;
    font-weight: 500;
    color: #000;
}

.page-form-space {
    height: 20px;
}


.page-form-file {
    display: block;
    margin: 20px 0;
    padding: 20px;
    border-radius: 10px;
    border: 1px dashed #cacaca;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.page-form-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

.page-form-file-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-form-file img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    opacity: 0.8;
}

.page-form-file-box-text {
    font-size: 14px;
    font-weight: 900;
}

.page-form-label {
    font-size: 13px;
    padding-left: 15px;
    margin-bottom: 5px;
    font-weight: bold;
    margin-top: 5px;
}

@media screen and (max-width: 900px) {

    .page-form {
        flex-direction: column;
    }

    .page-form-box {
        width: 100%;
    }

    .page-form-button {
        width: 100%;
    }

}





.gallery-input-file {
    position: relative;
    display: block;
    outline: none;
    margin: 10px 0;
    width: 100px;
    height: 100px;
}

.gallery-input-file-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 13px;
    vertical-align: middle;
    text-align: center;
    border-radius: 6px;
    height: 100px;
    padding: 10px 20px;
    line-height: 22px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: transform 0.5s;
    font-weight: 800;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #999;
}

.gallery-input-file-text {
    padding: 0 10px;
    line-height: 40px;
    display: inline-block;
    font-size: 12px;
    color: #666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery-input-file-text img {
    width: 300px;
    height: 300px;
    margin-top: 20px;
    border: 1px solid #cacaca;
    border-radius: 10px;
}

.gallery-input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

/* Focus */
.gallery-input-file input[type=file]:focus+.gallery-input-file-button {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

/* Disabled */
.gallery-input-file input[type=file]:disabled+.gallery-input-file-button {
    background-color: #eee;
}

.gallery-container {
    background: #f5f6f7;
    padding: 10px 15px;
    border-radius: 5px;
}

.gallery-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.gallery-text {
    font-size: 13px;
    font-weight: bold;
}

.gallery-item {
    width: 100px;
    height: 100px;
    border-radius: 2px;
    border: 1px solid #cacaca;
    margin: 10px 10px 10px 0;
    position: relative;
}

.gallery-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.gallery-item-remove {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s;
}

.gallery-item:hover .gallery-item-remove {
    opacity: 1;
}

.gallery-item-remove img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}





.characteristic-container {
    margin: 15px 0;
}

.characteristic-text {
    font-size: 13px;
    font-weight: bold;
}

.characteristic-button {
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: #222;
    color: white;
    font-weight: bold;
    font-size: 13px;
    transition: box-shadow 0.3s;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    align-self: center;
}

.characteristic-button:hover {
    box-shadow: 0 0 10px #222;
}

.characteristic-box {
    background: #f5f6f7;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.characteristic-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #cacaca;
    min-height: 40px;
}

.characteristic-name {
    width: 40%;
    font-weight: bold;
    font-size: 13px;
}

.characteristic-value {
    width: 40%;
    font-weight: bold;
    font-size: 13px;
}

.characteristic-item-remove {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    cursor: pointer;
}

.characteristic-item-remove img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}