
.data-layout-box {
    height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.data-layout-box span {
    color: #222;
    font-size: 30px;
}

.data-layout-box img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    filter: invert(1);
    opacity: 0.3;
}

.data-layout-box h2 {
    font-weight: 1000;
    color: #222;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
}

.data-layout-box p {
    font-size: 14px;
    color: #555;
    font-weight: 200;
    margin-top: 5px;
    text-align: center;
}

.data-layout-box .button {
    background: var(--primary);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 20px;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: box-shadow 0.4s;
    width: 200px;
    font-size: 14px;
}

.data-layout-box .button:hover {
    box-shadow: 0 0 20px var(--primary);
}