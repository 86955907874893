@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Regular.ttf');
    src: url('../fonts/GoogleSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Medium.ttf');
    src: url('../fonts/GoogleSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Bold.ttf');
    src: url('../fonts/GoogleSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Black.ttf');
    src: url('../fonts/RedHatDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-ExtraBold.ttf');
    src: url('../fonts/RedHatDisplay-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Bold.ttf');
    src: url('../fonts/RedHatDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-SemiBold.ttf');
    src: url('../fonts/RedHatDisplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Medium.ttf');
    src: url('../fonts/RedHatDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Regular.ttf');
    src: url('../fonts/RedHatDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../fonts/RedHatDisplay-Light.ttf');
    src: url('../fonts/RedHatDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}



:root {
    --primary: #9a0b0d;
    touch-action: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Google Sans', sans-serif;
}

a {
    text-decoration: none;
}

html,
body,
#root {
    min-height: 100vh;
    background-image: radial-gradient(circle, #ffffff, #f5f5f5, #ebebeb, #e2e1e1, #d8d7d7);
}

.app {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.container {
    width: calc(100% - 300px);
    min-height: 100%;
    transition: width 0.3s;
    overflow-x: hidden;
}

.container.minimized {
    width: calc(100% - 100px);
}


@media screen and (max-width: 900px) {

    .container,
    .container.minimized {
        width: 100%;
    }
}




.notification-popup {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.notification {
    color: white;
    font-size: 14px;
    display: flex;
}

.notification-html {
    margin-right: 20px !important;
    margin-left: 0 !important;
}

.notification-icon {
    font-size: 6px;
    margin-right: 10px !important;
    margin-left: 0 !important;
}




.dashboard-panel {
    background: white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px #d8d7d7;
    width: 300px;
    height: 150px;
}

.dashboard-text {
    font-size: 18px;
    color: #555;
}

.dashboard-text span {
    font-weight: bold;
    display: block;
}

.dashboard-logout {
    background: #222;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
    transition: box-shadow 0.4s;
}

.dashboard-logout:hover {
    box-shadow: 0 0 10px #222;
}