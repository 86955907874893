.header {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-user {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 25px;
    background: #fff;
    box-shadow: 0 0 5px #d8d7d7;
    padding-left: 15px;
    cursor: pointer;
}

.header-user-text {
    margin-left: 10px;
    margin-right: 10px;
}

.header-user-role {
    font-size: 12px;
    font-weight: 300;
    color: #555;
}

.header-user-name {
    font-weight: 800;
    color: #222;
    font-size: 14px;
}

.header-user>img {
    width: 35px;
    height: 35px;
    object-fit: contain;
    border: 1px solid var(--primary);
    border-radius: 100%;
    padding: 2px;
}

.header-user img.mobile-image {
    display: none;
}


.header-more {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header-more img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}


/* DROPDOWN MENU STYLES */
.header-dropdown-menu {
    position: absolute;
    z-index: 5;
    right: 17px;
    top: 70px;
    background: white;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px #ccc;
    width: 250px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;
}

.header-dropdown-menu.active {
    transition: visibility 0s, opacity 0.2s linear;
    visibility: visible;
    opacity: 1;
}

.header-dropdown-menu::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 20px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 3px;
    transform: rotate(45deg);
}

.header-dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    cursor: pointer;
}

.header-dropdown-menu-divider {
    border-bottom: 1px solid #dedede;
}

.header-dropdown-menu-item span {
    font-size: 14px;
    font-weight: 300;
    color: #141414;
    padding-left: 6px;
}

.header-dropdown-menu-item img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    opacity: 0.8;
}




@media screen and (max-width: 900px) {

    .header {
        top: 10px;
        right: 10px;
    }

    .header-user {
        width: 40px;
        padding: 0;
        background: transparent;
        box-shadow: none;
    }

    .header-user-text,
    .header-more {
        display: none;
    }

    .header-user img {
        display: none;
        width: 35px;
        height: 35px;
    }

    .header-user img.mobile-image {
        display: block;
    }

    .header-dropdown-menu {
        right: 12px;
    }

}